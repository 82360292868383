import { useRef } from 'react';
import { LogosWrapper, LogoWrapper } from './style';
import { useGeneratePositionedLogos } from './utils';

export const Logos = () => {
  const parentRef = useRef(null);
  const positionedLogos = useGeneratePositionedLogos(parentRef);

  return (
    <LogosWrapper ref={parentRef}>
      {positionedLogos.map((logo) => {
        const { fileName, ...logoStyle } = logo;
        return (
          <LogoWrapper key={fileName} {...logoStyle}>
            <img src={fileName} alt="logo" />
          </LogoWrapper>
        );
      })}
    </LogosWrapper>
  );
};
