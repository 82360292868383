import styled, { css, keyframes } from 'styled-components';
import { deepBlue, fontSizeL } from '../common/styles/variables';
import { LOGO_HEIGHT, LOGO_WIDTH } from './utils';

// Logos ==================
const position = ({ top, left }) => `top: ${top}px; left: ${left}px;`;
const floatAnimation = ({ xDistance, yDistance, duration }) => css`
  animation: ${duration}s ease-in-out infinite alternate ${keyframes`
    0%, 100% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(${xDistance}px, ${yDistance}px);
    }
  `};
`;

export const LogoWrapper = styled.div`
  position: absolute;
  width: ${LOGO_WIDTH}px;
  height: ${LOGO_HEIGHT}px;
  ${position}
  ${floatAnimation}
  & > * {
    max-height: ${LOGO_HEIGHT}px;
    max-width: ${LOGO_WIDTH}px;
    height: auto;
  }
`;

export const LogosWrapper = styled.div`
  position: relative;
  overflow: hidden;
  @media (max-width: 678px) {
    order: 2;
    height: 100vh !important;
  }
`;

// Error message ===============
export const Code = styled.h1`
  font-size: 100px;
  color: ${deepBlue};
`;

export const Subtitle = styled.h2`
  font-size: 48px;
`;

export const ErrorMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  & > * {
    max-width: 500px;
  }
  @media (max-width: 678px) {
    padding: 0;
  }
`;

export const Message = styled.p`
  font-size: ${fontSizeL};
`;

export const Link = styled.a.attrs({
  rel: 'noreferer',
  target: '_blank',
})`
  color: ${deepBlue};
  font-size: ${fontSizeL};
  &:hover {
    text-decoration: none;
  }
`;
