import { NEWSLETTER_URL } from '../../consts';
import useI18n from '../../lib/use_i18n';
import { Code, ErrorMessageWrapper, Link, Message, Subtitle } from './style';

export const ErrorMessage = ({ code }) => {
  const { translate } = useI18n('error_page');
  return (
    <ErrorMessageWrapper>
      <Code>{code}</Code>
      <Subtitle>{translate(`.${code}.subtitle`)}</Subtitle>
      <Message>{translate(`.${code}.message`)}</Message>
      <Link href={NEWSLETTER_URL}>{translate(`.newsletter`)}</Link>
    </ErrorMessageWrapper>
  );
};
