import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Logos } from './error_page/logos';
import GOhiringLogo from '../../images/logo_monochrome.svg';
import { ErrorMessage } from './error_page/error_message';

const PageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 70px 20px;
  position: relative;
  & > * {
    width: 50%;
    height: 100%;
  }
  @media (max-width: 678px) {
    flex-direction: column;
    & > * {
      width: 100%;
      height: auto;
    }
  }
`;

const Logo = styled.img`
  content: url(${GOhiringLogo});
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  width: auto;
  padding: 0;
  margin: 20px;
`;

export const ErrorPage = ({ code }) => {
  return (
    <PageWrapper>
      <Logo />
      <Logos />
      <ErrorMessage code={code} />
    </PageWrapper>
  );
};

ErrorPage.propTypes = {
  code: PropTypes.oneOf([400, 404, 500]),
};
