import { useEffect, useState } from 'react';
import concludis from '../../../images/partner_logos/concludis.svg';
import dvinci from '../../../images/partner_logos/dvinci.svg';
import freieUniversitatBerlin from '../../../images/partner_logos/freie-universitat-berlin.svg';
import hrWorks from '../../../images/partner_logos/hr-works.svg';
import indeed from '../../../images/partner_logos/indeed.svg';
import jobsora from '../../../images/partner_logos/jobsora.svg';
import jobvector from '../../../images/partner_logos/jobvector.svg';
import justRemote from '../../../images/partner_logos/just-remote.svg';
import kleinanzeigen from '../../../images/partner_logos/kleinanzeigen.svg';
import linkedIn from '../../../images/partner_logos/linked-in.svg';
import mhmHr from '../../../images/partner_logos/mhm-hr.svg';
import monster from '../../../images/partner_logos/monster.svg';
import personio from '../../../images/partner_logos/personio.svg';
import rexx from '../../../images/partner_logos/rexx.svg';
import stepStone from '../../../images/partner_logos/step-stone.svg';
import talentsConnect from '../../../images/partner_logos/talents-connect.svg';
import tikTok from '../../../images/partner_logos/tik-tok.svg';

export const svgFiles = [
  linkedIn,
  indeed,
  personio,
  rexx,
  stepStone,
  dvinci,
  talentsConnect,
  concludis,
  hrWorks,
  jobsora,
  jobvector,
  justRemote,
  kleinanzeigen,
  mhmHr,
  monster,
  tikTok,
  freieUniversitatBerlin,
];

export const LOGO_HEIGHT = 50;
export const LOGO_WIDTH = 150;

// generate a radom postion for logo el within the parent el
const generateRandomPosition = ({ parentWidth, parentHeight }) => {
  const randomX = Math.random() * (parentWidth - LOGO_WIDTH);
  const randomY = Math.random() * (parentHeight - LOGO_HEIGHT);
  return { left: randomX, top: randomY };
};

// calculates if potential new logo position would overlap with one of the existing logos
// we account for the animation movement as well
const isOverlapping = ({ newPosition, existingPositions }) => {
  return existingPositions.some(
    (pos) =>
      newPosition.left < pos.left + LOGO_WIDTH + 2 * X_MOVEMENT &&
      newPosition.left + LOGO_WIDTH + 2 * X_MOVEMENT > pos.left &&
      newPosition.top < pos.top + LOGO_HEIGHT + 2 * Y_MOVEMENT &&
      newPosition.top + LOGO_HEIGHT + 2 * Y_MOVEMENT > pos.top
  );
};

const X_MOVEMENT = 10; //px
const Y_MOVEMENT = 5; //px
const MIN_DURATION = 4; //s
const generateAnimationParams = () => ({
  xDistance: Math.random() * 2 * X_MOVEMENT - X_MOVEMENT, // Random X movement between -10 and 10
  yDistance: Math.random() * 2 * Y_MOVEMENT - Y_MOVEMENT, // Random Y movement between -5 and 5
  duration: Math.random() * 4 + MIN_DURATION, // Random duration between 4s and 8s
});

// it is possible that there is not enough space on the screen for all logos
// to avoid getting into infinite loop we must limit the number of attempts
// for trying to find a non-overlaping position for a logo.
const MAX_ATTEMPTS = 1000;

// returns a list of object that should be rendered into logo elememnts.
// obj has
export const useGeneratePositionedLogos = (parentRef) => {
  const [positionedLogos, setPositionedLogos] = useState([]);

  // we are using useEffect here and not simply useMemo because latter runs
  // during the rendering (ref.current would be null), while former runs
  // after DOM updates (ref.current is bind to the element)
  useEffect(() => {
    const parentEl = parentRef.current;
    if (parentEl) {
      const { clientWidth: parentWidth, clientHeight: parentHeight } = parentEl;

      let attempts = 0;
      const newLogos = [];

      while (newLogos.length < svgFiles.length && attempts < MAX_ATTEMPTS) {
        // if counter hits the MAX_ATTEMPTS value, we assume that there is
        // no space left, and we cut the logo list there
        attempts++;

        const newPosition = generateRandomPosition({
          parentWidth,
          parentHeight,
        });

        if (
          !isOverlapping({
            newPosition,
            existingPositions: newLogos,
          })
        ) {
          const positionedLogo = {
            ...newPosition,
            ...generateAnimationParams(),
            fileName: svgFiles[newLogos.length],
          };
          newLogos.push(positionedLogo);
          attempts = 0;
        }
      }
      setPositionedLogos(newLogos);
    }
  }, [parentRef]);
  return positionedLogos;
};
